.connect-your-wallet {
  text-align: center;
  font-weight: 500;
  margin-top: 100px;
  text-transform: uppercase;
  color:rgba(255, 255, 255, 0.9);
  font-size: 24px;
}

.tutorial {
  text-align: center;
  font-weight: 500;
  margin-top: 60px;
  text-transform: uppercase;
  color:rgba(255, 255, 255, 0.9);
  font-size: 18px;
}

.frame-gen-main {
  height: 100%;
  min-height: 100vh;
  background-color: #101010;
  color: black !important;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.frame-h {
  color:rgba(255, 255, 255, 0.9);
  font-size: 42px !important;
  font-weight: bold !important;
  margin-top: 20px;
  /* background-color: blue !important; */
}


