.wallet-adapter-button-start-icon {
  display: none !important;
}

.nav-upper {
  display: flex;
  justify-content: space-between;
}

.nav-background {
  background-color: #111625;
  /* background-color: #1b253d; */
}

.connect-button.MuiButtonBase-root {
  background-color: #fff;
  color: #000;
  padding: 10px 12px;
  border-radius: 18px;
  font-weight: bold;
  text-transform: uppercase;
  border: 2px solid black;
  box-shadow: none;
  flex-shrink: 0;
  width: 180px;
  justify-content: center;
}

.connect-button.MuiButtonBase-root:hover {
  background-color: #fff;
  box-shadow: none;
}

.logo {
  width: 100px;
  margin-left: 20px !important;
  /* height: 100px; */
}

.nav-menu .nav-menu-item {
  cursor: pointer;
  color: #F2F3F4;
  text-align: center;
  text-decoration: none;
}

.nav-menu .nav-menu-item.nav-menu-selected {
  font-weight: 700;
  color: #dc1fff;
}

.nav-menu .nav-menu-item:hover {
  color: #dc1fff
}