@tailwind base;
@tailwind components;
@tailwind utilities;

ul {
  list-style: none; /* Remove default bullets */
}

ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #Ffffff;
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

html {
  font-size: 15px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

body {
  margin: 0;
  font-family: 'Jost', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111625;
}

.background-main {
  background-color: #101010;
}

.background-light {
  background-color: #111625;
}

.text-white {
  color: #Ffffff;
}

.secondary-dark-text {
  color: #a500cb
}

code {
  font-family: 'Jost' !important;
}

@layer components {
  .content-box {
    width: 90%;
    margin: 0 auto;
  }

  @media screen and (min-width: 992px) {
    .content-box {
      width: 100%;
      margin: 0 auto;
      max-width: 900px;
    }
  }

  @media screen and (min-width: 1100px) {
    .content-box {
      width: 100%;
      margin: 0 auto;
      max-width: 1000px;
    }
  }

  @media screen and (min-width: 1300px) {
    .content-box {
      width: 100%;
      margin: 0 auto;
      max-width: 1200px;
    }
  }
}

.MuiStepIcon-root.MuiStepIcon-active {
  font-size: 32px;
}

.footer-sub-div {
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #000;
  justify-content: center;
  flex-direction: row;
}

.footer-desc-link {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 4px;
  color: #a500cb !important;
  /* font-size: 16px; */
}

.footer-socials-logo {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  margin: 5px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}
